const Bike = () => {
  return (
    <svg
      className="navigation-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <rect width="64" height="64" rx="32" fill="#5E7812" />
      <g
        transform="translate(4
        , 4)"
      >
        <path
          d="M12.521 13.9789C12.521 12.6682 12.521 11.3574 12.521
        9.99286C13.9744 9.99286 15.3997 9.99286 16.9021 9.99286C16.9021 8.6423
        16.9021 7.34293 16.9021 6.00768C18.4076 6.00768 19.836 6.00768 21.3301
        6.00768C21.3301 4.67068 21.3301 3.36955 21.3301 2.03955C25.7934 2.03955
        30.1907 2.03955 34.6471 2.03955C34.6471 3.33805 34.6471 4.62518 34.6471
        5.97486C36.1429 5.97486 37.5823 5.97486 39.0759 5.97486C39.0759 7.32324
        39.0759 8.60905 39.0759 9.94999C40.5722 9.94999 42.0142 9.94999 43.5131
        9.94999C43.5131 11.3758 43.5131 12.7474 43.5131 14.1653C44.9966 14.1653
        46.4229 14.1653 47.9322 14.1653C47.9322 16.8384 47.9322 19.4652 47.9322
        22.144C49.4403 22.144 50.8666 22.144 52.3226 22.144C52.3226 26.1454
        52.3226 30.0934 52.3226 34.1144C50.8854 34.1144 49.4469 34.1144 47.9467
        34.1144C47.9467 36.8737 47.9467 39.5613 47.9467 42.2966C46.4574 42.2966
        45.0312 42.2966 43.5424 42.2966C43.5424 43.6445 43.5424 44.9461 43.5424
        46.3001C42.0374 46.3001 40.5941 46.3001 39.0974 46.3001C39.0974 47.6402
        39.0974 48.9269 39.0974 50.2752C37.6042 50.2752 36.1639 50.2752 34.6637
        50.2752C34.6637 51.6236 34.6637 52.9116 34.6637 54.2289C30.2043 54.2289
        25.8061 54.2289 21.3616 54.2289C21.3616 52.9304 21.3616 51.6411 21.3616
        50.298C19.8749 50.298 18.4325 50.298 16.9336 50.298C16.9336 48.9579
        16.9336 47.6704 16.9336 46.3272C15.4536 46.3272 14.0291 46.3272 12.5302
        46.3272C12.5302 44.9736 12.5302 43.6747 12.5302 42.3202C11.0151 42.3202
        9.57355 42.3202 8.08737 42.3202C8.08737 39.5757 8.08737 36.8877 8.08737
        34.1367C6.60118 34.1367 5.15918 34.1367 3.68262 34.1367C3.68262 30.127
        3.68262 26.1799 3.68262 22.1654C5.13205 22.1654 6.57099 22.1654 8.05805
        22.1654C8.05805 19.4122 8.05805 16.726 8.05805 13.9619C9.58318 13.9619
        11.0602 13.9619 12.5372 13.9619C12.5372 16.6857 12.5372 19.4096 12.5372
        22.1996C11.0501 22.1996 9.61118 22.1996 8.1718 22.1996C8.1718 26.1279
        8.1718 29.9888 8.1718 33.9127C9.64705 33.9127 11.0869 33.9127 12.5621
        33.9127C12.5621 35.3871 12.5621 36.7989 12.5621 38.2427C14.0216 38.2427
        15.4431 38.2427 16.9227 38.2427C16.9227 36.9232 16.9227 35.6356 16.9227
        34.2955C18.422 34.2955 19.8636 34.2955 21.3524 34.2955C21.3524 32.9533
        21.3524 31.6653 21.3524 30.3633C22.8569 30.3633 24.3138 30.3633 25.779
        30.3633C25.779 31.7038 25.779 33.0071 25.779 34.3629C24.3164 34.3629
        22.8884 34.3629 21.3917 34.3629C21.3917 35.717 21.3917 37.0199 21.3917
        38.3604C19.8942 38.3604 18.4657 38.3604 17.0207 38.3604C17.0207 40.998
        17.0207 43.5955 17.0207 46.231C18.4811 46.231 19.9095 46.231 21.4057
        46.231C21.4057 47.571 21.4057 48.8752 21.4057 50.2052C25.8367 50.2052
        30.1995 50.2052 34.6187 50.2052C34.6187 48.8739 34.6187 47.5706 34.6187
        46.2174C36.1158 46.2174 37.5578 46.2174 39.0562 46.2174C39.0562 44.8743
        39.0562 43.5828 39.0562 42.2909C40.5013 42.2909 41.9468 42.2909 43.4522
        42.2909C43.4522 42.0297 43.4527 41.8446 43.4522 41.6595C43.4452 39.3797
        43.4374 37.1004 43.4317 34.8205C43.4295 33.9368 43.4317 33.9368 44.3294
        33.9368C45.498 33.9368 46.6661 33.9368 47.8386 33.9368C47.8386 29.984
        47.8386 26.0946 47.8386 22.1659C46.3669 22.1659 44.9389 22.1659 43.4527
        22.1659C43.4527 20.8424 43.4527 19.5562 43.4527 18.252C41.9722 18.252
        40.5507 18.252 39.0737 18.252C39.0737 19.5719 39.0737 20.8577 39.0737
        22.2079C37.581 22.2079 36.1434 22.2079 34.6694 22.2079C34.6694 23.3257
        34.6721 24.3779 34.6686 25.4301C34.6659 26.2687 34.7451 26.1747 33.9178
        26.1769C32.7147 26.1808 31.5111 26.1777 30.2879 26.1777C30.2879 27.5073
        30.2879 28.7761 30.2879 30.0934C30.5031 30.0934 30.6891 30.0934 30.8754
        30.0934C31.8944 30.0934 32.9133 30.0934 33.9322 30.0934C34.6694 30.0934
        34.6694 30.0938 34.6694 30.8559C34.6694 31.4951 34.6694 32.1343 34.6694
        32.7731C34.6694 33.197 34.6694 33.6214 34.6694 34.0917C36.1779 34.0917
        37.6037 34.0917 39.0733 34.0917C39.0733 36.8628 39.0733 39.5854 39.0733
        42.3079C37.5972 42.3079 36.1211 42.3079 34.5994 42.3079C34.5994 39.5464
        34.5994 36.8611 34.5994 34.1114C33.115 34.1114 31.6761 34.1114 30.1759
        34.1114C30.1759 32.7634 30.1759 31.4794 30.1759 30.1354C28.6761 30.1354
        27.2381 30.1354 25.7457 30.1354C25.7457 28.7883 25.7457 27.5034 25.7457
        26.1572C24.2499 26.1572 22.8097 26.1572 21.3266 26.1572C21.3266 24.8066
        21.3266 23.5182 21.3266 22.1724C19.8434 22.1724 18.4036 22.1724 16.9091
        22.1724C16.9091 19.4289 16.9091 16.7435 16.9091 13.9785C15.4098 13.9789
        13.9656 13.9789 12.521 13.9789ZM30.1912 22.1291C31.7067 22.1291 33.1478
        22.1291 34.6388 22.1291C34.6388 20.7611 34.6388 19.4569 34.6388
        18.1041C36.1333 18.1041 37.5753 18.1041 39.0204 18.1041C39.0204 15.4021
        39.0204 12.7513 39.0204 10.0309C37.5394 10.0309 36.0847 10.0309 34.5955
        10.0309C34.5955 8.67205 34.5955 7.38186 34.5955 6.06586C30.1969 6.06586
        25.835 6.06586 21.4066 6.06586C21.4066 7.39674 21.4066 8.70049 21.4066
        10.0585C19.8999 10.0585 18.4587 10.0585 17.0242 10.0585C17.0242 11.3837
        17.0242 12.6524 17.0242 13.9676C18.4859 13.9676 19.9134 13.9676 21.4171
        13.9676C21.4171 16.7098 21.4171 19.4066 21.4171 22.1571C22.9177 22.1571
        24.3422 22.1571 25.8214 22.1571C25.8214 23.4976 25.8214 24.7839 25.8214
        26.0828C27.3011 26.0828 28.7242 26.0828 30.1912 26.0828C30.1912 24.7777
        30.1912 23.5051 30.1912 22.1291Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default Bike;
