import React from "react";
import { useState, useEffect } from "react";
const TekstDesktop = () => {
  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 4000);
  }, []);

  return (
    <div className=" desktop">
      {showElement ? (
        <p className="click-alert">
          Naciśnij i przytrzymaj, aby przybliżyć lub użyj scrolla
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TekstDesktop;
