const LocationIcon = () => {
  return (
    <svg
      className="LocationIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <path
        d="M11.6591 3.16675H28.9318V6.62129H11.6591V3.16675ZM8.20455 10.0758V6.62129H11.6591V10.0758H8.20455ZM8.20455 23.894H4.75V10.0758H8.20455V23.894ZM11.6591 27.3486H8.20455V23.894H11.6591V27.3486ZM15.1136 30.8031H11.6591V27.3486H15.1136V30.8031ZM18.5682 34.2577H15.1136V30.8031H18.5682V34.2577ZM22.0227 34.2577V37.7122H18.5682V34.2577H22.0227ZM25.4773 30.8031V34.2577H22.0227V30.8031H25.4773ZM28.9318 27.3486V30.8031H25.4773V27.3486H28.9318ZM32.3864 23.894V27.3486H28.9318V23.894H32.3864ZM32.3864 10.0758H35.8409V23.894H32.3864V10.0758ZM32.3864 10.0758V6.62129H28.9318V10.0758H32.3864ZM23.75 13.5304H16.8409V20.4395H23.75V13.5304Z"
        fill="white"
      />
    </svg>
  );
};
export default LocationIcon;
