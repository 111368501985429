import BackArrow from "./icons/BackArrow";

const ProjectIdea = (): JSX.Element => {
  return (
    <main>
      <BackArrow />
      <div className="padding-left-extra">
        <h1>Idea Projektu</h1>
      </div>
      <section className="interestings-one">
        <img className="img-height" src="./ProjectIdea_3.jpg" alt="" />

        <div>
          <h2>Założenie projektowe</h2>
          <p>
            Głównym celem artystycznej części pracy dyplomowej było
            zaprojektowanie strony internetowej, której wizualizacja łączyłażby
            elementy reprezentacji obiektów 3D w przestrzeni miejskiej. Na
            wstępnym etapie projektu przeprowadzono badania terenowe, mające na
            celu wyselekcjonowanie kategorii elementów w przestrzeni publicznej
            spełniających następujące kryteria:
            <br /> 1.Element musi być stworzony przez człowieka oraz
            wyprodukowany.
            <br /> 2.Element powinien znajdować się w danym miejscu od dłuższego
            czasu.
            <br /> 3.Na elemencie powinny być widoczne ślady oddziaływania
            warunków atmosferycznych, które wpływają na jego stan i prowadzą do
            jego degradacji. Po przeprowadzeniu kilku wypraw i obserwacji
            przestrzeni miejskiej, wytypowano kilka obiektów spełniających dane
            kryterium. Są to: rowery, samochody oraz opuszczone miejsca na
            świeżym powietrzu, zwane potocznie squatami.
          </p>
        </div>
      </section>
      <section className="interestings-two">
        <img className="img-height" src="./ProjectIdea_2.jpg" alt="" />

        <div>
          <h2>Cel projektu</h2>
          <p>
            Głównym celem tej aplikacji było uświadomienie każdemu z nas, że
            codziennie, będąc uczestnikami przestrzeni publicznej, mijamy
            ogromną liczbę przedmiotów, które zostały porzucone, a ich ilość
            ciągle wzrasta. Przedmioty te często umykają naszej uwadze, co jest
            spowodowane faktem, że ich obecność jest silnie zintegrowana z
            przestrzenią publiczną. W wyniku procesów niszczenia struktura
            przedmiotów ulega degradacji i ich kolory stają się coraz mniej
            widoczne. W końcu przedmioty te blakną i stają się niewidoczne dla
            przechodniów. Jednak po pewnym czasie te obiekty znikają w
            niejasnych okolicznościach. Możemy jedynie spekulować, że te
            przedmioty zostały usunięte przez zarząd miasta, ktoś zdecydował się
            je przywłaszczyć lub zostały one zabrane na wysypiska śmieci. Celem
            strony jest przybliżenie problemu pozostawiania wielkoga arytowych
            „śmieci” w przestrzeni publicznej. W związku z znikaniem tych
            obiektów najlepszym sposobem na ich zarchiwizowanie okazało się:
            wykonanie modeli 3D, które oddają ogólną bryłę obiektu, oraz
            wykonanie zdjęć fotograficznych detali obiektu. Zdjęcia te zostały
            wykonane przy użyciu technologii analogowej, uważanej za trwałą i
            trudną do sfabrykowania.
          </p>
        </div>
      </section>
      <section className="interestings-one">
        <img className="img-height" src="./ProjectIdea_1.jpg" alt="" />

        <div>
          <h2>Powód wyboru tematu</h2>
          <p>
            Temat „śmiecenia” pojawiał się wielokrotnie w trakcie mojej
            edukacji, jednak nigdy nie czułem, że został przeze mnie w pełni
            zgłębiony. Samo zjawisko jest dla mnie interesujące i staram się
            zwracać na nie uwagę każdego dnia. Głównym motywem wyboru tego
            tematu była chęć stworzenia książki fotograficznej, która
            zawierałaby zdjęcia porzuconych rowerów wraz ze współrzędnymi
            miejsc, w których zostały znalezione. Jednak po powrocie w te same
            miejsca po kilku latach, nie znalazłem tam tych rowerów i odczułem
            niedosyt z powodu niepełnej archiwizacji tych przedmiotów. W związku
            z tym zdecydowałem się sięgnąć po szerszy wachlarz środków przekazu,
            aby w przypadku zniknięcia tych obiektów nadal posiadać dowód ich
            obecności.
          </p>
        </div>
      </section>
      <section className="interestings-two">
        <img className="img-sqr" src="./ProjectIdea_4.jpg" alt="" />

        <div>
          <h2>Dobór technologii</h2>
          <p>
            W kwestii fotograficznej, wybór aparatu nie odgrywał wielkiej roli –
            każdy aparat analogowy nadawał się do fotografowania obiektów.
            Jednak sytuacja przy tworzeniu modeli okazała się dość
            skomplikowana. Pierwotny plan zakładał użycie skanera firmy Leica,
            niestety ze względu na jego długi czas skanowania oraz trudności w
            przemieszczaniu, został on odrzucony. Ostatecznie wybrany został
            telefon iPhone 13 Pro Max, który posiada wbudowany lidar
            umożliwiający dokonywanie pomiarów wielkości przedmiotów. Programem,
            który został wykorzystany do stworzenia tych modeli, jest Lumai,
            oparta na technologii NeRF. NeRF w tym przypadku oznacza jednak
            Neural Radiance Fields, czyli neuronowe pola radiacyjne. Jest to
            technika, która wykorzystuje sztuczną inteligencję do opracowania
            trójwymiarowej sceny, co jest poprzedzone „treningiem” na
            dwuwymiarowych zdjęciach danego miejsca.
          </p>
        </div>
      </section>
    </main>
  );
};

export default ProjectIdea;
