const NewPlace = () => {
  return (
    <svg
      className="navigation-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <rect width="64" height="64" rx="32" fill="#E08C2B" />

      <path
        d="M27.25 0.25H22.75V4.75H18.25V9.25H13.75V13.75H9.25V18.25H4.75V22.75H0.25V27.25H4.75V31.75H9.25V36.25H13.75V40.75H18.25V45.25H22.75V49.75H27.25V45.25H31.75V40.75H36.25V36.25H40.75V31.75H45.25V27.25H49.75V22.75H45.25V18.25H40.75V13.75H36.25V9.25H31.75V4.75H27.25V0.25ZM27.25 4.75V9.25H31.75V13.75H36.25V18.25H40.75V22.75H45.25V27.25H40.75V31.75H36.25V36.25H31.75V40.75H27.25V45.25H22.75V40.75H18.25V36.25H13.75V31.75H9.25V27.25H4.75V22.75H9.25V18.25H13.75V13.75H18.25V9.25H22.75V4.75H27.25ZM27.25 13.75H22.75V27.25H27.25V13.75ZM27.25 31.75H22.75V36.25H27.25V31.75Z"
        fill="white"
        transform="translate(7, 7)"
      />
    </svg>
  );
};
export default NewPlace;
