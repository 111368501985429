import React from "react";
import { Link } from "react-router-dom";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Mapa from "./Mapa";
import ProjectIdea from "./ProjectIdea";
import SingleObject from "./SingleObject";
import GroupObjects from "./GroupObjects";
import Interestings from "./Interestings";
import Form from "./Form";
import Nopage from "./404";
function Home() {
  return (
    <div>
      <Link to="/mapa">
        <div className="home-tlo">
          <div className="home-block">
            <svg
              className="home-ikona-x"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <path
                d="M10 10H14V14H10V10ZM18 18H14V14H18V18ZM22 22H18V18H22V22ZM26 22H22V26H18V30H14V34H10V38H14V34H18V30H22V26H26V30H30V34H34V38H38V34H34V30H30V26H26V22ZM30 18V22H26V18H30ZM34 14V18H30V14H34ZM34 14V10H38V14H34Z"
                fill="white"
              />
            </svg>

            <h1 className="h1-test">Czym jest ta strona</h1>
            <p>
              Strona ta została stworzona w ramach projektu licencjackiego. Jej
              głównym celem jest zarchiwizowanie przestrzeni publicznej. Szybkie
              zmiany społeczne i gospodarcze sprawiają, że na ulicach naszych
              miast pojawia się coraz wiecej pojazdów, miejsc odpoczynku oraz
              zabudowań .<br></br>W rezultacie wiele z tych obiektów zostaje
              porzucone, a następnie przez lata pozostają w otoczeniu
              niezauważone przez innych. Jednak równie często znikają nagle i
              bez śladu. Celem tej mapy jest zarejestrowanie istnienia tych
              przedmiotów, aby każdy mógł obejrzeć je w postaci modelu
              trójwymiarowego oraz detalicznych zdjęć, które mają na celu
              zwiększenie wrażenia immersji tych obiektów.
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="mapa" element={<Mapa />} />
        <Route path="galeria-obiektow" element={<GroupObjects />} />
        <Route path="galeria-obiektow/:id" element={<SingleObject />} />
        <Route path="ciekawostki" element={<Interestings />} />
        <Route path="dodaj-swoj-obiekt" element={<Form />} />
        <Route path="idea-projektu" element={<ProjectIdea />} />
        <Route path="*" element={<Nopage />} />
      </Routes>
    </Router>
  );
}

export default App;
