import React, {
  useEffect,
  useRef,
  WheelEvent as ReactWheelEvent,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls, Html } from "@react-three/drei";
import { Suspense } from "react";
import * as THREE from "three";
import objData from "./data.json";
import { Link } from "react-router-dom";
import BackArrow from "./icons/BackArrow";
import LocationIcon from "./icons/LocationIcon";
import TekstMobile from "./TekstMobile";
import TekstDesktop from "./TekstDesktop";

const SingleObject = () => {
  const { id } = useParams();
  const [scale, setScale] = useState(100);
  const object = objData.features.find(
    (obj) => String(obj.properties.ID) === id
  );

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    const handleMouseWheel = (event: WheelEvent) => {
      setScale((state) => {
        let newScale = state - event.deltaY;
        if (newScale <= 0) {
          newScale = 100;
        }
        return newScale;
      });
    };

    canvas.addEventListener("wheel", handleMouseWheel);

    return () => {
      canvas.removeEventListener("wheel", handleMouseWheel);
    };
  }, []);

  if (!object) {
    return (
      <div className="nopage">
        <h1>Nie znaleziono objectu o ID: {id}</h1>
        <Link className="group-objects-button" to={`/mapa/`}>
          Zacznij od nowa
        </Link>
      </div>
    );
  }

  function RotatingModel(props: any) {
    const modelRef = useRef<THREE.Group | null>(null);

    useFrame(({ clock }) => {
      if (modelRef.current) {
        if (window.innerWidth <= 768) {
          const elapsed = clock.getElapsedTime();
          modelRef.current.rotation.y = elapsed * 0.5;
        }
      }
    });

    const { scene } = useGLTF(object.properties.MODEL);
    return <primitive ref={modelRef} object={scene} scale={props.scale} />;
  }

  function Loader() {
    return (
      <Html center>
        <span className="loader">🌀</span>
        <p>Wczytywanie</p>
      </Html>
    );
  }

  function handlemouseadd() {
    setScale((state) => {
      let newScale = state + 100;
      if (newScale <= 0) {
        newScale = 100;
      }
      return newScale;
    });
  }

  function handlemousesub() {
    setScale((state) => {
      let newScale = state - 100;
      if (newScale <= 0) {
        newScale = 100;
      }
      return newScale;
    });
  }

  return (
    <main className="parent">
      <BackArrow />
      <section className="col-1">
        <header className="marker-group">
          <h1 className="padding-left-extra padding-right-extra">
            {object.properties.NAME}
          </h1>
          <div className="single-icon-padding-left">
            <div className="single-LocationIcon">
              <LocationIcon />
            </div>

            <Link
              to={object.properties.CORDINATES}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                {object.geometry.CORDINATES[0]} <br />
                {object.geometry.CORDINATES[1]}
              </p>
            </Link>
          </div>
        </header>
        <div>
          <Canvas
            onMouseOver={() => {
              document.body.style.overflow = "hidden";
            }}
            onMouseOut={() => {
              document.body.style.overflow = "";
            }}
            dpr={[1, 2]}
            shadows
            camera={{ fov: 30 }}
            style={{
              height: "80vh",
              width: "auto",
              padding: "0px 20px 20px 20px",
            }}
            ref={canvasRef}
          >
            <Suspense fallback={<Loader />}>
              <PresentationControls
                speed={1.5}
                global
                zoom={3}
                polar={[-0.1, Math.PI / 4]} //To robi zooom
              >
                <Stage>
                  <RotatingModel scale={scale} />
                </Stage>
              </PresentationControls>
            </Suspense>
          </Canvas>
          <div className="zoom-button-object-group">
            <button className="zoom-button-object" onClick={handlemouseadd}>
              +
            </button>
            <button className="zoom-button-object" onClick={handlemousesub}>
              {" "}
              -{" "}
            </button>
          </div>
        </div>
        <TekstDesktop />
        <TekstMobile />
        <p className="object-description"> {object.properties.INFO}</p>
      </section>
      <div className="photo-galery">
        {object.properties.PHOTOS.map((photo, index) => (
          <img
            key={index}
            className="img-sqr small"
            src={photo}
            alt="zdjecie"
          />
        ))}
      </div>
    </main>
  );
};

export default SingleObject;
