import React from "react";
import { useState, useEffect } from "react";
const TekstMobile = () => {
  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 3000);
  }, []);

  return (
    <div className=" mobile">
      {showElement ? (
        <p className="click-alert">
          Użyj + aby przybliżyć, oddal obiekt za pomocą -
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TekstMobile;
